import React, { ComponentType, useEffect } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

const useStore = createStore({
    cookieToast: true,
})

export function setCookie(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        return (
            <Component
                {...props}
                onClick={() => {
                    localStorage.setItem(
                        "cookie-accepted",
                        JSON.stringify(true)
                    )
                    setStore({ cookieToast: false })
                }}
            />
        )
    }
}

export const getCookieStatus = (Component): ComponentType => {
    return (props) => {
        const [store, setStore] = useStore()
        const cookieStorage = JSON.parse(
            localStorage.getItem("cookie-accepted")
        )

        useEffect(() => {
            console.log(cookieStorage)
            if (cookieStorage === true) {
                setStore({ cookieToast: false })
            }
        }, [])

        return (
            <Component
                {...props}
                style={{
                    opacity: store.cookieToast ? 1 : 0,
                    visibility: store.cookieToast ? "visible" : "hidden",
                    transition: "300ms",
                }}
            />
        )
    }
}

export function resetCookie(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        return (
            <Component
                {...props}
                onClick={() => {
                    localStorage.setItem(
                        "cookie-accepted",
                        JSON.stringify(false)
                    )
                    setStore({ cookieToast: true })
                }}
            />
        )
    }
}
